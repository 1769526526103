<!-- eslint-disable vue/no-mutating-props -->
<template>
    <v-navigation-drawer v-model="state.drawer" style="width: 25vw;" width="100px" color="white"
        :mini-variant="state.mini" :mobile-break-point="mobileBreakPoint" app temporary right="">
        <v-list dense nav class="py-0">
            <v-subheader color="#6D4C41" class="q-pa-md title">Menu</v-subheader>
            <v-divider></v-divider>
            <v-list-item to="/" color="#6D4C41">
                <v-list-item-content>
                    <v-list-item-title>Inicio</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item to="/about" color="#6D4C41">
                <v-list-item-content>
                    <v-list-item-title>Acerca de</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item to="/services" color="#6D4C41">
                <v-list-item-content>
                    <v-list-item-title>Servicios</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item to="/products" color="#6D4C41">
                <v-list-item-content>
                    <v-list-item-title>Productos</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item to="/contact" color="#6D4C41">
                <v-list-item-content>
                    <v-list-item-title>Contacto</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>
<script>
export default {
    props: {
        state: {
            type: Object,
            default: function () {
                return {
                    drawer: true,
                mini: true
            }
        }
      }  
    },
    data () {
        return {
            mobileBreakPoint: 960,
            right: null
        }
    }
}
</script>
