<template>
  <v-app>
    <heado></heado>
    <SidebarRight :state="drawerState" />
    <v-main>
      <router-view />
    </v-main>
    <footer-default></footer-default>
  </v-app>
</template>

<script>
import Heado from "@/components/layouts/ToolbarDefault.vue";
import FooterDefault from "@/components/layouts/FooterDefault.vue";
import SidebarRight from "@/components/layouts/SidebarRight.vue";
export default {
  name: "App",
  components: {
    Heado,
    FooterDefault, SidebarRight
  },
  data: () => ({
    drawerState: {
      drawer: false,
      mini: false
    },
    position: 0
    //
  }),
  methods: {
    openDrawer () {
      // modificar propiedad :mobile-break-point si quieres que el isMobile sea menor a 1264px
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.drawerState.drawer = true
        this.drawerState.mini = !this.drawerState.mini
      } else {
        this.drawerState.drawer = !this.drawerState.drawer
        this.drawerState.mini = false
      }
    }
  }
};
</script>
<style scoped>
.indigo--text {
  color: #004975 !important;
}
</style>
