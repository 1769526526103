/* eslint-disable prettier/prettier */
<template>
  <div>
    <v-footer flat class="px-0">
      <div class="row py-4 primary px-0 mx-0">
        <div class="col-lg-2 col-md-2 col-xs-12 q-px-sm"></div>
        <div class="col-lg-3 col-md-3 col-xs-12 q-px-sm white--text">
          <p class="text-h6 mb-1">Dirección</p>
                         
              <p class="mb-0">
                 <v-icon dark>mdi-map-marker</v-icon>
                  Flor de Verónica, No. 105, Col.
                  Gaviotas Norte, Vhsa. Tab. C.P 86067
              </p>
          
          <p class="mb-0">
            <span><v-icon dark>mdi-email</v-icon></span>
            contacto@servicleantabasco.com
          </p>
          <p class="mb-0">
            <span><v-icon dark>mdi-email</v-icon></span>
            cotizacion@servicleantabasco.com
          </p>
        </div>
        <div
          class="col-lg-3 col-md-3 col-xs-12 q-px-lg text-center white--text"
        >
          <p class="text-h6 mb-1">Atención</p>
          <p class="mb-1 white--text">
            <a
              style="text-decoration: none"
              class="white--text"
              href="tel:+529932662275 "
            >
              <span><v-icon dark>mdi-phone</v-icon></span>993 266 2275
            </a>
          </p>
          <p class="mb-1">
            <a
              style="text-decoration: none"
              class="white--text"
              href="tel:+529932175619"
            >
              <span><v-icon dark>mdi-phone</v-icon></span>993 217 5619</a
            >
          </p>
        </div>
        <div class="col-lg-3 col-md-3 col-xs-12 q-px-lg white--text">
          <p class="text-h6 mb-1">Horarios</p>
          <p class="mb-1">
            <span><v-icon dark>mdi-store-clock</v-icon></span> Lunes a Viernes 8AM a 2PM y 4:00PM a 6:00PM
          </p>
          <p class="mb-1">
            <span><v-icon dark>mdi-store-clock</v-icon></span> Sábado (cerrado)
          </p>
          <p class="mb-1">
            <span><v-icon dark>mdi-store-clock</v-icon></span> Domingo (cerrado)
          </p>
        </div>
      </div>
    </v-footer>
  </div>
</template>
<script>
export default {
  setup() {},
}
</script>
