<template>
  <div>
    <v-app-bar app flat color="#fff" dark>

      <div class="d-flex align-center">
        <v-img alt="Vuetify Logo" class="shrink mr-2" contain src="@/assets/logo-servi1.png"
          transition="scale-transition" width="40" />
        <v-img alt="Vuetify Name" class="shrink mt-1 hidden-sm-and-down" contain min-width="100"
          src="@/assets/logo-serv5i.png" width="200" />
      </div>

      <v-spacer></v-spacer>
      <v-btn to="/" text color="#6D4C41" class="text-initial body-1 hidden-sm-and-down">
        <span class="mr-2">Inicio</span>
      </v-btn>
      <v-btn to="/about" text color="#6D4C41" class="text-initial body-1 hidden-sm-and-down">
        <span class="mr-2">Acerca de</span>
      </v-btn>
      <v-btn to="/services" text color="#6D4C41" class="text-initial body-1 hidden-sm-and-down">
        <span class="mr-2">Servicios</span>
      </v-btn>
      <v-btn to="/products" text color="#6D4C41" class="text-initial body-1 hidden-sm-and-down">
        <span class="mr-2">Productos</span>
      </v-btn>
      <v-btn to="/contact" text color="#6D4C41" class="text-initial body-1 hidden-sm-and-down">
        <span class="mr-2">Contacto</span>
      </v-btn>
      <v-app-bar-nav-icon class="hidden-md-and-up" color="#6D4C41" @click.stop="openDrawer"></v-app-bar-nav-icon>
    </v-app-bar>
    <SidebarRight :state="drawerState" />
  </div>
</template>
<script>
import SidebarRight from './SidebarRight.vue'
export default {
  name: 'TodoItem',
  components: { SidebarRight },
  data: () => ({
    drawerState: {
      drawer: false,
      mini: false
    },
    position: 0
  }),
  methods: {
    openDrawer () {
      // modificar propiedad :mobile-break-point si quieres que el isMobile sea menor a 1264px
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.drawerState.drawer = true
        this.drawerState.mini = !this.drawerState.mini
      } else {
        this.drawerState.drawer = !this.drawerState.drawer
        this.drawerState.mini = false
      }
    }
  }
}
</script>
<style scoped>
.text-initial {
  text-transform: initial;
}
</style>
